import { render, staticRenderFns } from "./PacienteAdd.vue?vue&type=template&id=d926b282"
import script from "./PacienteAdd.vue?vue&type=script&lang=js"
export * from "./PacienteAdd.vue?vue&type=script&lang=js"
import style0 from "./PacienteAdd.vue?vue&type=style&index=0&id=d926b282&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports