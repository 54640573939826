<template>
  <div>
    <v-col cols="12" sm="4">
      <h4>Agregar nuevo Paciente</h4>
    </v-col>
    <v-form>
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1">
          Datos Personales
        </v-stepper-step>
        <v-stepper-content step="1">

          <!-- VALIDATIONS DISABLED FOR NOW
                <v-text-field class="styled-input textInput" v-model="paciente.nombre" clearable type="text" :rules="requiredRules" label="Nombre"></v-text-field>
                <v-text-field class="styled-input textInput" v-model="paciente.apellido" clearable type="text" :rules="requiredRules" label="Apellido"></v-text-field>
                <v-text-field class="styled-input numberInput" v-model="paciente.documento" counter="8" clearable type="number" :rules="[(v) => (v && v.length <= 8) || 'La cedula no puede ser de mas de 8 numeros']" label="Documento"></v-text-field> -->

          <v-text-field class="styled-input" v-model="paciente.nombre" clearable type="text"
            label="Nombre"></v-text-field>
          <v-text-field v-model="paciente.apellido" clearable type="text" label="Apellido"></v-text-field>
          <v-text-field v-model="paciente.documento" counter="8" clearable type="number" label="Documento"></v-text-field>
          <v-text-field class="styled-input numberInput" v-model="paciente.telefono" clearable type="number"
            label="Telefono"></v-text-field>
          <v-btn width="auto" class="mx-2" color="primary" @click="e6 = 2">
            Siguiente
          </v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="e6 > 2" step="2">
          Datos Medicos
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-combobox v-model="paciente.mutualista" clearable :items="mutualistas" label="Seleccione Mutualista"
            auto-select-first single hide-selected @input="searchInputMutualista = null"
            :search-input.sync="searchInputMutualista"></v-combobox>
          <v-combobox v-model="paciente.emergenciamovil" :items="emergencias" label="Seleccione Emergencia"
            auto-select-first single hide-selected @input="searchInputEmergencia = null"
            :search-input.sync="searchInputEmergencia"></v-combobox>
          <v-combobox v-model="paciente.patologiascronicas" :items=patologias item-text='nombrePatologia' item-value='id'
            data-vv-name="patologias" label="Patologias Cronicas" auto-select-first chips small-chips deletable-chips
            hide-selected multiple @input="searchInputPatologia = null"
            :search-input.sync="searchInputPatologia"></v-combobox>
          <v-text-field v-model="paciente.antecedentesdiabeticos" clearable type="text"
            label="Antecedentes Diabeticos"></v-text-field>
          <v-text-field v-model="paciente.medicamentosdiarios" clearable type="text"
            label="Medicamentos Diarios"></v-text-field>
          <v-text-field v-model="paciente.alergiamedicamentos" clearable type="text"
            label="Alergias a Medicamentos"></v-text-field>
          <v-btn width="auto" color="primary" @click="e6 = 3">
            Siguiente
          </v-btn>
          <v-btn width="auto" class="mx-2" color="secondary" @click="e6 = e6 - 1">
            Anterior
          </v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="e6 > 3" step="3">
          Actividad Fisica & Autorizacion
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-text-field v-model="paciente.formadetrabajo" clearable type="text" label="Forma de Trabajo (Remoto, Presencial, Híbrido)"></v-text-field>
          <v-text-field v-model="paciente.actividadfisicatipo" clearable type="text"
            label="Tipo de Actividad Fisica (Aeróbico, Anaeróbico, Flexibilidad, Equilibrio)"></v-text-field>
          <v-card-actions class="pa-4">
            Frecuencia de Actividad Fisica:
            <v-spacer></v-spacer>
            <v-rating v-model="paciente.actividadfisicafrecuencia" empty-icon="mdi-heart-outline" full-icon="mdi-heart"
              half-icon="mdi-heart-half" half-increments hover length="6" size="20"></v-rating>
          </v-card-actions>
          <v-checkbox class="align-center justify-center" label="Autoriza uso de material"
            v-model="paciente.materialautorizado"></v-checkbox>
          <v-btn color="primary"
            @click="savePaciente(); reset, resetValidation, snackbar = true, e6 = e6 - 2, newPaciente()">
            Agregar
          </v-btn>
          <v-btn class="mx-2" color="secondary" @click="e6 = e6 - 1">
            Anterior
          </v-btn>
        </v-stepper-content>
      </v-stepper>

      <v-btn color="red" @click="goToList" style="margin-top:2rem;"> Cancelar</v-btn>
      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

    </v-form>

    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      Paciente guardado correctamente a las {{ showEditedTime() }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import PacienteDataService from "../services/PacienteDataService";
import PatologiaDataService from '../services/PatologiaDataService';
import MutualistaDataService from '../services/MutualistaDataService';
import EmergenciaDataService from '../services/EmergenciaDataService';
import ConfirmDialogue from './ConfirmDialogue.vue';
export default {
  name: "add-paciente",
  components: {
    ConfirmDialogue
  },
  data() {
    return {
      e6: 1,
      dialog: false,
      snackbar: false,
      requiredRules: [
        v => !!v || 'Este campo es requerido'
      ],
      multiLine: true,
      paciente: {
        id: null,
        nombre: "",
        apellido: "",
        telefono: "",
        documento: "",
        mutualista: "",
        emergenciamovil: "",
        antecedentesdiabeticos: "",
        patologiascronicas: "",
        medicamentosdiarios: "",
        alergiamedicamentos: "",
        formadetrabajo: "",
        actividadfisicatipo: "",
        actividadfisicafrecuencia: 0,
        materialautorizado: false,
        pacienteactivo: false
      },
      mutualistas: null,
      emergencias: null,
      patologias: null,
      searchInputMutualista: null,
      searchInputEmergencia: null,
      searchInputPatologia: null,
      submitted: false,

    };

  },
  methods: {
    async goToList() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Volver al listado?',
        message: 'Todos los cambios se perderan.',
        okButton: 'Volver',
      })
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.$router.push("/pacientes");
      } else {
        // Doing nothing now, can throw an alert() too
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    getRequestParams(searchWhat, page, pageSize) {
      let params = {};
      if (searchWhat) {
        params["nombre"] = searchWhat;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    retrieveMutualistas() {
      const params = this.getRequestParams("", 1, 24);
      MutualistaDataService.getAll(params)
        .then((response) => {
          const { mutualistas, totalPages } = response.data;
          this.mutualistas = mutualistas.map(this.getDisplayMutualista);
          const arr = [];
          this.mutualistas.forEach((object) => {
            arr.push(object.nombreMutualista);
          });
          this.mutualistas = arr;
          this.totalPages = totalPages;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayMutualista(mutualista) {
      return {
        nombreMutualista: mutualista.nombre,
      };
    },
    retrievePatologias() {
      const params = this.getRequestParams("", 1, 24);
      PatologiaDataService.getAll(params)
        .then((response) => {
          const { patologias, totalPages } = response.data;
          this.patologias = patologias.map(this.getDisplayPatologia);
          const arr = [];
          this.patologias.forEach((object) => {
            arr.push(object.nombrePatologia);
          });
          this.patologias = arr;
          this.totalPages = totalPages;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayPatologia(patologia) {
      return {
        nombrePatologia: patologia.nombre,
      };
    },

    retrieveEmergencias() {
      const params = this.getRequestParams("", 1, 24);
      EmergenciaDataService.getAll(params)
        .then((response) => {
          const { emergencias, totalPages } = response.data;
          console.log("retrieveEmergencias()");
          console.log(response.data);
          this.emergencia = emergencias.map(this.getDisplayEmergencia);
          const arr = [];
          this.emergencia.forEach((object) => {
            arr.push(object.nombreEmergencia);
          });
          this.emergencias = arr;
          this.totalPages = totalPages;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayEmergencia(emergencia) {
      return {
        nombreEmergencia: emergencia.nombre,
      };
    },

    showEditedTime() {
      var editedTime = new Date();
      editedTime = editedTime.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      return editedTime
    },
    // THIS IS FOR THE DROPDOW LIST
    change(val) {
      console.log('val', val)
      this.option = val
    },
    select() {
      console.log('doing ', this.option)
    },
    // THIS IS FOR THE DROPDOW LIST
    savePaciente() {
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>" + JSON.stringify(this.paciente.patologiascronicas));

      var patologias_array_result = Object.keys(this.paciente.patologiascronicas).map((key) => [key, this.paciente.patologiascronicas[key]]);

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>" + patologias_array_result);


      var data = {
        nombre: this.paciente.nombre,
        apellido: this.paciente.apellido,
        documento: this.paciente.documento,
        telefono: this.paciente.telefono,
        mutualista: this.paciente.mutualista,
        emergenciamovil: this.paciente.emergenciamovil,
        antecedentesdiabeticos: this.paciente.antecedentesdiabeticos,
        patologiascronicas: this.paciente.patologiascronicas,
        medicamentosdiarios: this.paciente.medicamentosdiarios,
        alergiamedicamentos: this.paciente.alergiamedicamentos,
        formadetrabajo: this.paciente.formadetrabajo,
        actividadfisicatipo: this.paciente.actividadfisicatipo,
        actividadfisicafrecuencia: this.paciente.actividadfisicafrecuencia,
        // Add logic for True and false: 
        // <label><strong>Status:</strong></label> {{ currentTutorial.published ? "Material Autorizado" : "No" }}
        materialautorizado: this.paciente.materialautorizado,
        pacienteactivo: true
      };
      PacienteDataService.create(data)
        .then((response) => {
          this.paciente.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    newPaciente() {
      this.submitted = false;
      this.paciente = {};


    },
  },

  mounted() {
    this.retrievePatologias();
    this.retrieveMutualistas();
    this.retrieveEmergencias();
  },
};
</script>

<style>
.submit-form {
  max-width: 800px;
}

.styled-input label[for] {
  height: 30px;
  font-size: 12pt;
}

/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
